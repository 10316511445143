import React from 'react';
import { graphql, Link, useStaticQuery } from 'gatsby';
import { LocalizedLink } from 'gatsby-theme-i18n'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useTranslation } from 'react-i18next'
import { faCheckCircle } from '@fortawesome/free-solid-svg-icons';
import {
  Button, Form, FormGroup, Label, Input, FormText,
} from 'reactstrap';
import { trackCustomEvent } from 'gatsby-plugin-google-analytics';
import Blog01 from '../images/blog/blog.jpg';
import Layout from '../layouts/index';
import BlogArticle from '../components/Blog';
import Clients from '../components/Clients';
import ProductCLM1 from '../images/product-clm-1.png';
import Lawyer from '../images/lawyer.jpg';
import ProductCLM2 from '../images/product-clm-2.png';
import DemoForm from '../components/DemoForm';
import DashboardWaves from '../images/dashboard-starter-waves-es.svg';
import Dashboard from '../images/500-startups/dashboard-starter.svg';
import Dashboard2 from '../images/500-startups/dashboard-starter-2.svg';
import Logo500 from '../images/500-startups/logo-500.png';
import SemiCircle from '../images/500-startups/semi-circle.svg';
import MujerStarter from '../images/mujer-starter.jpg';
import LegalAdvisorHero from '../images/legal-advisor-hero.png';

import Hero from '../images/hero.png';


function LegalAdvisor({ pageContext: { locale }, location }) {
    const { t } = useTranslation()
  return (
    <Layout id="landing" locale={locale} bodyClass="page-home landing" inverted border location={location}>

      <section className="bg-grey">
        <div className="container mt-5">
          <div className="row d-flex">
            <div className="col-lg-6 col-sm-12 align-self-center">
              <h1 className="mb-1">{t('legalAdvisor.heroTitleOne')} <span className="font-weight-bold underline-primary">{t('legalAdvisor.heroTitleTwo')}</span></h1>
              <p className="lead text-primary font-weight-bold">{t('legalAdvisor.heroTitleThree')}</p>
              <p className="text-muted text-justify w-75 mb-3">{t('legalAdvisor.heroTextOne')}</p>
              <DemoForm type="legalAdvisor" />
            </div>
            <div className="col-lg-6 col-sm-12 d-flex">
              <img src={LegalAdvisorHero} className="img-fluid align-self-center" alt="Módulo Legal Advisor - Trato" />
            </div>
          </div>
        </div>
      </section>

      <section className="bg-light">
        <div className="container">
          <div className="row">
            <div className="col-lg-6 d-flex">
              <img alt="" src={ProductCLM1} className="img-fluid align-self-center" />
            </div>
            <div className="col-lg-6">
              <p>{t('legalAdvisor.sectionOneText')}</p>
              <h5 className="font-weight-bold mb-2">{t('legalAdvisor.sectionOneSubtitle')}</h5>
              <ul className="list-unstyled">
                <li className="text-muted mb-1"><FontAwesomeIcon icon={faCheckCircle} className="text-primary align-baseline icon-sm mr-1" /> {t('legalAdvisor.sectionOneFeature1')}</li>
                <li className="text-muted mb-1"><FontAwesomeIcon icon={faCheckCircle} className="text-primary align-baseline icon-sm mr-1" />{t('legalAdvisor.sectionOneFeature2')}</li>
                <li className="text-muted mb-1"><FontAwesomeIcon icon={faCheckCircle} className="text-primary align-baseline icon-sm mr-1" />{t('legalAdvisor.sectionOneFeature3')}</li>
                <li className="text-muted mb-1"><FontAwesomeIcon icon={faCheckCircle} className="text-primary align-baseline icon-sm mr-1" />{t('legalAdvisor.sectionOneFeature4')}</li>
                <li className="text-muted mb-1"><FontAwesomeIcon icon={faCheckCircle} className="text-primary align-baseline icon-sm mr-1" />{t('legalAdvisor.sectionOneFeature5')}</li>
                <li className="text-muted mb-1"><FontAwesomeIcon icon={faCheckCircle} className="text-primary align-baseline icon-sm mr-1" />{t('legalAdvisor.sectionOneFeature6')}</li>
                <li className="text-muted mb-1"><FontAwesomeIcon icon={faCheckCircle} className="text-primary align-baseline icon-sm mr-1" />{t('legalAdvisor.sectionOneFeature7')}</li>
                <li className="text-muted"><FontAwesomeIcon icon={faCheckCircle} className="text-primary align-baseline icon-sm mr-1" />{t('legalAdvisor.sectionOneFeature8')}</li>
              </ul>
            </div>
          </div>
        </div>
      </section>

      <section className="bg-grey">
        <div className="container">
          <div className="row">
            <div className="col-lg-6">
              <h2 className="font-weight-bold mb-3">{t('legalAdvisor.sectionTwoTitle')}</h2>
              <p>{t('legalAdvisor.sectionTwoText')}</p>
              <h5 className="font-weight-bold mb-2">{t('legalAdvisor.sectionTwoSubtitle')}</h5>
              <ul className="list-unstyled">
                <li className="text-muted mb-1"><FontAwesomeIcon icon={faCheckCircle} className="text-primary align-baseline icon-sm mr-1" />{t('legalAdvisor.sectionTwoFeature1')}</li>
                <li className="text-muted mb-1"><FontAwesomeIcon icon={faCheckCircle} className="text-primary align-baseline icon-sm mr-1" />{t('legalAdvisor.sectionTwoFeature2')}</li>
                <li className="text-muted mb-1"><FontAwesomeIcon icon={faCheckCircle} className="text-primary align-baseline icon-sm mr-1" />{t('legalAdvisor.sectionTwoFeature3')}</li>
                <li className="text-muted mb-1"><FontAwesomeIcon icon={faCheckCircle} className="text-primary align-baseline icon-sm mr-1" />{t('legalAdvisor.sectionTwoFeature4')}</li>
                <li className="text-muted"><FontAwesomeIcon icon={faCheckCircle} className="text-primary align-baseline icon-sm mr-1" />{t('legalAdvisor.sectionTwoFeature5')}</li>
              </ul>
              <small className="text-muted">{t('legalAdvisor.sectionTwoSmall')}</small>
            </div>
            <div className="col-lg-6">
              <div className="text-center mb-3">
                <img alt="" src={Lawyer} className="img-fluid align-self-center w-75 rounded" />
              </div>
              <div className="card bg-light shadow-sm w-75 rounded mx-auto">
                <div className="card-body w-100 px-2">
                  <div className="row">
                    <div className="col-lg-7 col-md-12 my-1">
                      <h5 className="font-weight-bold mb-0">{t('legalAdvisor.ctaTitle3')}</h5>
                      <p className="text-muted font-weight-thin mb-0">{t('legalAdvisor.ctaText3')}</p>
                    </div>
                    <div className="col-lg-5 col-md-12 d-flex my-1">
                      <LocalizedLink to="/contacto" className="badge badge-primary-50 px-2 py-1 w-100 align-self-center" onClick={(e) => {
                        trackCustomEvent({
                          category: 'Contacto - Legal Advisor',
                          action: 'Contactar en sección Módulo Legal Advisor',
                        });
                      }}>{t('legalAdvisor.ctaButton3')}
                      </LocalizedLink>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="bg-grey">
        <div className="container">

          <div className="card bg-light rounded shadow-sm mt-5">
            <div className="card-body">
              <div className="row d-flex">
                <div className="col-lg-8 align-self-center py-2 px-4">
                  <h3>{t('legalAdvisor.ctaTitle1')} <span className="font-weight-bold underline-primary">{t('legalAdvisor.ctaTitle2')}</span></h3>
                  <p className="lead text-muted">{t('legalAdvisor.ctaText')}</p>
                  <DemoForm type="legal-advisor" />
                </div>
                <div className="col-lg-4 d-none d-lg-block">
                  <img src={require(`../images/${t('legalAdvisor.ctaImage')}.png`)} className="img-fluid rounded-right" alt="Legal Advisor de Trato | Asesoría para emprendedores"/>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>


    </Layout>
  );
}

export default LegalAdvisor;
